.container-slider{
    background-color: white;
}
.container-slider h2{
    text-align: center;
    padding-top: 50px;
}
.border-bottom-slider{
    border-bottom: 2px solid red;
    margin: auto;
    width: 260px;
    margin-bottom: 50px;
}
.slider{
    display: flex;
    width: 100%;
    margin: auto;
    padding-bottom: 50px;
}
.slide{
    width: 80%;
    margin: auto;
    display: flex;
}
.text-slider{
    margin: auto;
    width: 40%;
    text-align: center;
}
.avant{
    float: left;
    cursor: pointer;
}
.apres{
    float: right;
    cursor: pointer;
}
.border-soustitre-station{
    border: 1px solid red;
    width: 100px;
    margin: auto;
    margin-bottom: 20px;
}
