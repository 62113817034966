.container-jumbotron{
    height: 100%;
    background-color: rgba(220, 220, 220, 1);
    text-align: center;
    padding-bottom: 75px;
}
.jumbotron-text{
    width: 70%;
    margin: auto;
}
.text-jumbotron{
    margin-top: 50px;
    color: grey;
    margin-bottom: 50px;
}
.titre-jumbotron{
    padding-top: 2rem;
    color: black;
}
.border-titre-jumbotron{
    border-bottom: 1px solid red;
    width: 100px;
    margin: auto;
}