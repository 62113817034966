body{
    background-color: #efeded;
}
.container-form{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 100px;
    margin-bottom: 100px;
}
form{
    border: 2px dotted black;
    border-radius: 2%;
    height: 100%;
    text-align: center;
    padding: 20px;
}
.input-form{
    margin-bottom: 25px
}
.input-form input{
    padding: 10px;
    margin: 10px;
    width: 300px;
}
.input-reservation{
    display: flex;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(131, 186, 0);
}